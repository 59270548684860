var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"custom-table",attrs:{"headers":_vm.headers,"items":_vm.stores,"loading":_vm.isLoading,"loading-text":_vm.$t('GENERAL.LOADING_DATA'),"footer-props":{
    'items-per-page-options': [10, 25, 100],
    'items-per-page-text': _vm.$t('GENERAL.ROWS_PER_PAGE'),
    'page-text': ("{0}-{1} " + (_vm.$t('GENERAL.OF')) + " {2}")
  },"no-data-text":_vm.$t('GENERAL.NO_DATA'),"search":_vm.search},scopedSlots:_vm._u([{key:"header.generateInvoices",fn:function(ref){
  var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v(" fas fa-question-circle ")])]}}],null,true)},[_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('STORE.HINT_INVOICE_RELEVANT')))])])])]}},{key:"item",fn:function(props){return [_c('tr',{staticClass:"pointer",on:{"click":function($event){return _vm.openDetail(props.item)}}},[_c('td',[_vm._v(_vm._s(props.item.name))]),_c('td',[_vm._v(_vm._s(props.item.address.city))]),_c('td',{staticClass:"text-center"},[(props.item.status)?_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v("fas fa-check")]):_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v("fas fa-times-circle")])],1),_c('td',{staticClass:"text-center"},[(props.item.generateInvoices)?_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v("fas fa-check")]):_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v("fas fa-times-circle")])],1)])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }