













































import Store from '@/models/Store.model';
import { namespace } from 'vuex-class';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ApplicationStoreMutations } from '@/store/application.store';
import { ROUTE_STORE } from '@/router/routes';

const ApplicationStore = namespace('application');

@Component
export default class StoreListComponent extends Vue {
  @Prop({ required: true })
  public stores!: Store[];

  @Prop({ default: false })
  private isLoading!: boolean;

  @Prop({ default: '' })
  private search!: string;

  @ApplicationStore.Mutation(ApplicationStoreMutations.SET_STORE)
  private setCurrentStore!: (store: Store) => void; 

  private headers = [
    { text: this.$t('STORE.NAME'), align: 'start', sortable: true, value: 'name' },
    { text: this.$t('STORE.CITY'), align: 'start', sortable: true, value: 'address.city' },
    { text: this.$t('STORE.ACTIVE'), align: 'center', sortable: false, value: 'status' },
    { text: this.$t('STORE.INVOICE_RELEVANT'), align: 'center', sortable: false, value: 'generateInvoices' },
  ];

  private openDetail(store: Store) {
    this.setCurrentStore(store);
    this.$router.push({ name: ROUTE_STORE, params: { id: store.id! } });
  }
}
